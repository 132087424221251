import "./App.css"
import {createContext, useContext, useState} from "react"
import {SupabaseClient, createClient} from "@supabase/supabase-js"
import {Signal, signal} from "@preact/signals"
import {Logger} from "@pkg/logging/Logger.js"
import {AuthContext, Login, createAuthContext} from "./Auth.js"
import {Note, NoteEditor} from "./Note.js"
import {Box} from "./Layout.js"

const logger = new Logger("App")

export interface AppContext extends AuthContext {
    notes: Signal<Note[]>
}

const AppContext = createContext<AppContext>(null!)

export function useAppContext(): AppContext {
    return useContext(AppContext)
}

export function createAppContext(): AppContext {
    const supabase = createSupabaseClient()
    const context: AppContext = {
        notes: signal([]),
        ...createAuthContext(supabase),
    }
    createNote(context)
    return context
}

function createSupabaseClient(): SupabaseClient {
    if (!process.env.SUPABASE_PROJECT_ID) {
        throw new Error(`Missing process.env.SUPABASE_PROJECT_ID`)
    }
    if (!process.env.SUPABASE_PUBLIC_API_KEY) {
        throw new Error(`Missing process.env.SUPABASE_PUBLIC_API_KEY`)
    }
    return createClient(
        `https://${process.env.SUPABASE_PROJECT_ID}.supabase.co`,
        process.env.SUPABASE_PUBLIC_API_KEY,
    )
}

export const App = ({context}: {context: AppContext}) => {
    return (
        <AppContext.Provider value={context}>
            {!context.auth.session.value ? <Login /> : <LoggedIn />}
        </AppContext.Provider>
    )
}

const LoggedIn = () => {
    const context = useAppContext()
    const notes = context.notes.value
    const [currentNote, setCurrentNote] = useState<Note | null>(null)
    if (!currentNote && notes[0]) {
        setCurrentNote(notes[0])
    }
    return (
        <div className="AppLayout">
            <header className="AppHeader"></header>
            <div className="AppViewport">
                <section className="Viewlet AppSidebar">
                    <Box as="header" flex>
                        <h2 style={{flex: 1}}>Notes</h2>
                        <Box flex>
                            <button onClick={() => createNote(context)}>
                                +
                            </button>
                        </Box>
                    </Box>
                    <ol style={{listStyle: "none", margin: 0, padding: 0}}>
                        {notes.map((note) => {
                            return (
                                <li
                                    key={note.title}
                                    onClick={() => {
                                        setCurrentNote(note)
                                    }}
                                    style={
                                        note === currentNote
                                            ? {
                                                  background:
                                                      "rgb(220, 220, 220)",
                                              }
                                            : {}
                                    }
                                >
                                    {note.title}
                                </li>
                            )
                        })}
                    </ol>
                </section>
                <section className="Viewlet">
                    {currentNote && <NoteEditor note={currentNote} />}
                </section>
            </div>
        </div>
    )
}

function createNote(context: AppContext) {
    const note: Note = {
        title: `${new Date().toLocaleDateString()}`,
        content: {type: "doc", content: [{type: "paragraph"}]},
    }
    const notes = context.notes.value
    if (notes.find((n) => n.title == note.title)) {
        let i = 1
        let title = note.title
        do {
            note.title = `${title} - (${i})`
            i++
        } while (notes.find((n) => n.title == note.title))
    }
    logger.debug("createNote", "created new note", {title: note.title})
    context.notes.value = [...notes, note]
}
