import "./NoteEditor.css"
import {Editor, JSONContent} from "@tiptap/core"
import {StarterKit} from "@tiptap/starter-kit"
import {useEffect, useRef} from "react"

export interface Note {
    title: string
    content: JSONContent
}

export const NoteEditor = ({note}: {note: Note}) => {
    const elementRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const element = elementRef.current
        if (!element) return

        const editor = createNoteEditor(element, note)
        setTimeout(() => {
            ;(element?.firstChild as HTMLElement)?.focus()
        })
        return () => {
            editor.destroy()
        }
    }, [note])
    return <div className="NoteEditor" ref={elementRef} />
}

function createNoteEditor(element: HTMLElement, note: Note): Editor {
    const editor = new Editor({
        element,
        extensions: [StarterKit],
        content: note.content,
        injectCSS: false,
    })
    editor.on("update", () => {
        note.content = editor.getJSON()
    })
    return editor
}
