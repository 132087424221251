import {createElement} from "react"

interface BoxProps extends React.HTMLProps<"div"> {
    as?: string
    flex?: boolean
}
export const Box = ({as, flex, ...rest}: BoxProps) => {
    const style: React.CSSProperties = {}
    if (flex) {
        style.display = "flex"
    }
    return createElement(as ?? "div", {
        ...rest,
        style: {...style, ...rest.style},
    })
}
