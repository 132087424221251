import "./Auth.css"
import {Session, SupabaseClient} from "@supabase/supabase-js"
import {Signal, signal} from "@preact/signals"
import {Auth} from "@supabase/auth-ui-react"
import {ThemeSupa} from "@supabase/auth-ui-shared"
export {type Session} from "@supabase/supabase-js"
import {Logger} from "@pkg/logging/Logger.js"
import {useAppContext} from "./App.js"

const logger = new Logger("Auth")

export interface AuthContext {
    auth: {
        supabase: SupabaseClient
        session: Signal<Session | null>
    }
}

export function createAuthContext(supabase: SupabaseClient): AuthContext {
    return {
        auth: {
            supabase,
            session: signal(null),
        },
    }
}

export function useSession(): Session {
    const session = useAppContext().auth.session.value
    if (!session) {
        throw logger.newError(
            "useSession",
            "Session does not exist. This should only be called once we know the user is authenticated.",
        )
    }
    return session
}

export const Login = () => {
    const ctx = useAppContext()
    return (
        <div className="Login">
            <form className="LoginForm">
                <Auth
                    supabaseClient={ctx.auth.supabase}
                    appearance={{theme: ThemeSupa}}
                    providers={["google", "github"]}
                    onlyThirdPartyProviders
                />
            </form>
        </div>
    )
}

export async function initAuth(context: AuthContext) {
    logger.debug("initAuth", "get initial session state")
    const result = await context.auth.supabase.auth.getSession()

    context.auth.session.value = result.data.session
    if (context.auth.session.value) {
        logger.debug("initAuth", "has initial session")
    } else {
        logger.debug("initAuth", "no initial session; needs login.")
    }
    context.auth.supabase.auth.onAuthStateChange((event, session) => {
        logger.debug("onAuthStateChange", "auth state changed", {event})
        context.auth.session.value = session
    })
}

export function getUserDisplayName(session: Session) {
    return session.user!.identities![0].identity_data!.name
}
