import "preact/debug"
import "@pkg/normalize/normalize.css"
import {createRoot} from "react-dom/client"
import {
    ConsoleTransport,
    setLoggerTransports,
} from "@pkg/logging/LoggerTransport.js"
import {Logger} from "@pkg/logging/Logger.js"
import {App, createAppContext} from "./App.js"
import {initAuth} from "./Auth.js"

const logger = new Logger("main")

async function main() {
    setLoggerTransports([new ConsoleTransport({verbose: true})])

    registerServiceWorker()
    const context = createAppContext()
    await initAuth(context)

    const root = createRoot(document.getElementById("root")!)
    root.render(<App context={context} />)
}

async function registerServiceWorker() {
    try {
        await navigator.serviceWorker.register("/ServiceWorker.js")
        logger.debug(
            "registerServiceWorker",
            "service worker registration successful",
        )
    } catch (e) {
        logger.debug(
            "registerServiceWorker",
            "service worker registration failed",
            {error: e},
        )
    }
}

main()
